
import Vue, { PropType } from "vue";

import { Exercise } from "@prestonly/preston-common";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import HintCard from "@/components/HintCard.vue";
import { DialogI } from "@/types/dialog";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "DisplaySingleHintDialog",
  components: {
    BaseDialog,
    HintCard,
    Btn,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  computed: {
    hintIds(): string[] {
      return this.dialog.config.payload?.hints || [];
    },
    exerciseId(): string[] {
      return this.dialog.config.payload?.exerciseId || "";
    },
    exercise(): Exercise {
      return this.$store.getters["exercise/getById"](this.exerciseId);
    },
  },

  methods: {
    getHintById(id: string) {
      return this.$store.getters["hint/getHintById"](id);
    },
  },

  async created() {
    const exerciseDispatcher = () => this.$store.dispatch("exercise/getSingle", { id: this.exerciseId });
    const hintDispatchers = this.hintIds.map((hintId) => () => this.$store.dispatch("hint/getSingle", { id: hintId }));
    await Promise.all(
      ([] as Promise<any>[]).concat(
        hintDispatchers.map((d) => d()),
        exerciseDispatcher()
      )
    );
  },
});
